.description {
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 10px;
}

input, textarea {
	font-family: inherit;
	font-weight: inherit;
	resize: none;
	margin-top: 20px;
	border-radius: 7px;
	border: 1px solid #D5D5D5;
	width: calc(100% - 30px);
	padding: 13px;
	font-size: 16px;
	padding-left: 15px;
	padding-right: 15px;
	color: rgb(47, 47, 47);
}

.button {
	margin-top: 20px;
	border-radius: 7px;
	border: 2px solid #2da562;
	width: calc(100% - 30px);
	padding: 15px;
	font-size: 18px;
	text-align: center;
	background-color: #2da562;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: background-color 0.3s;
	
}

a .button {
	text-decoration: none;
}

.button:hover {
	background-color: #2a9c5d;
}

.error {
	color: red;
	text-align: center;
	opacity: 0.7;
}

.title {
	text-align: center;
	font-size: 20px;
	margin-bottom: 30px;
	font-weight: 600;
}

.buttonDisabled {
	background-color: #95d2b0;
	border-color: #95d2b0;
	cursor: no-drop;
}

.buttonDisabled:hover {
	background-color: #95d2b0;
	border-color: #95d2b0;
}
