.bodyWrapper {
	width: calc(100% - 60px);
	max-width: 500px;
	position: relative;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	background-color: transparent;
	padding: 20px;
	padding-top: 30px;
	padding-bottom: 40px;
}

.logoWrapper {
	height: 55px;
	width: 185px;
	background-color: transparent;
	display: block;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.logo {
	height: 55px;
	width: 185px;
	background-color: transparent;
	display: block;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	background-image: url(../public/assets/logo-big.png);
	background-size: auto 55px;
	background-repeat: no-repeat;
}

.box {
	background-color: #fff;
	margin-top: 30px;
	padding: 40px;
	padding-right: 50px;
	padding-left: 50px;
	border-radius: 10px;

	box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 10px;
}

.footer {
	margin-top: 15px;
	text-align: center;
	font-size: 13px;
}

.placeIdToolBottom {
	margin-top: 20px;
}

.placeIdToolBottom a {
	padding: 5px 20px;
	background-color: rgb(52, 52, 52);
	border-radius: 40px;
	text-decoration: none;
	color: #fff;
	opacity: 0.7;
	transition: opacity 0.3s;
}

.placeIdToolBottom a:hover {
	opacity: 1;
}

@media screen and  (max-width: 500px) {
	.box {
		background-color: #fff;
		margin-top: 30px;
		padding: 40px;
		padding-right: 30px;
		padding-left: 30px;
		border-radius: 10px;

		box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 10px;
	}
}

.local {
	text-align: center;
	margin-top: 20px;
	background-color: rgb(76, 201, 76);
	color: #fff;
	border-radius: 3px;
	padding: 20px;
}

a {
	color: inherit;
}
