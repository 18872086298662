.title {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
}

.description {
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 20px;
}

.question {
	margin-top: 30px;
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 4px;
}

.answer {
	font-size: 14px;
}

.back {
	text-align: center;
	font-size: 18px;
	padding: 20px;
	margin-top: 30px;
}

code {
	background-color: rgb(240, 240, 240);
	padding: 2px 4px 2px 4px;
	border-radius: 2px;
}
