.title {
	text-align: center;
	font-size: 20px;
	margin-bottom: 30px;
	font-weight: 600;
}

.description {
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 20px;
}

.error {
	color: red;
	text-align: center;
	opacity: 0.7;
}

.button {
	margin-top: 20px;
	border-radius: 7px;
	border: 2px solid #2da562;
	width: calc(100% - 30px);
	padding: 15px;
	font-size: 18px;
	text-align: center;
	background-color: #2da562;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: background-color 0.3s;
	
}

a .button {
	text-decoration: none;
}

.button:hover {
	background-color: #2a9c5d;
}
